<template>
    <div class="view shop">
        <section class="hero is-dark is-bold">
            <div class="bg-filter"></div>
            <div class="hero-body">
                <div class="container">
                    <h1 class="title">
                        MARIER SKINCARE currently has {{ numOfTreatments }} treatments.
                    </h1>
                    <!-- <h2 class="subtitle">
                        subtitle
                    </h2> -->
                </div>
            </div>
            <div class="hero-foot">
                <nav class="tabs is-boxed is-fullwidth">
                    <div class="container">
                        <ul>
                            <li v-bind:class="{ 'is-active': $route.name === 'Salon-settings' }">
                                <router-link to="/salon">
                                    <span class="icon is-small">
                                        <b-icon icon="shopping"></b-icon>
                                    </span>
                                    <span>
                                        Settings
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': $route.name === 'Salon-faqs' }">
                                <router-link to="/salon/faqs">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="question"></font-awesome-icon>
                                    </span>
                                    <span>
                                        FAQ
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': $route.name === 'Salon-categories' }">
                                <router-link to="/salon/categories">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="tags"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Treatment Categories
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': ['Salon-series', 'add-treatment-series', 'edit-treatment-series', 'add-treatment', 'edit-treatment'].includes($route.name) }">
                                <router-link to="/salon/series">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="grip-horizontal"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Treatments
                                    </span>
                                </router-link>
                            </li>
                            <li v-bind:class="{ 'is-active': ['Salon-bookings', 'bookings-details'].includes($route.name) }">
                                <router-link to="/salon/bookings">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="shopping-basket"></font-awesome-icon>
                                    </span>
                                    <span>
                                        Bookings
                                    </span>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </section>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'Treatments',
        data() {
            return {
                filterRole: 'customer'
            }
        },
        computed: {
            mall() {
                return this.$store.state.mall
            },

            salon() {
                return this.$store.state.salon.salon
            },

            numOfTreatments() {
                return this.$store.state.salon.salon.treatments.length
            }
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
    .shop {
        .hero {
            background-image: url("../assets/hero_background_3.png");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;

            & > .hero-body {
                & > .container {
                    width: 100%;
                }
            }

            & > .hero-foot {
                .tabs {
                    ul {
                        margin-left: 10px;
                        margin-right: 10px;

                        li {
                            &.is-active {
                                color: #363636 !important;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
